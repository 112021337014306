import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '80%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '100%'
  },
  columnHeader: {
    width: '16%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 10,
  },
  cell: {
    width: '16%',
    fontSize: 10,
    textAlign: 'left',
  },
  lastColumn: {
    width: '20%',
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  totalsHeadersLeft: {
    width: '80%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 10,
    marginRight: 2
  },
  totalsValue: {
    width: '20%',
    textAlign: 'left',
    fontSize: 10,
  },
  customerHeader: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    margin: 8
  },
  wideColumn: {
    width: '32%',

  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
};

const ClosedMasterPDF = ({ report, startDate, endDate }) => {
  // Determine the title based on the start and end dates
  const dateRangeTitle = (() => {
    if (!startDate && !endDate) {
      return 'All Invoice Dates';
    }

    if (startDate && endDate) {
      return `All Invoice Dates between ${startDate} and ${endDate}`;
    }

    if (startDate) {
      return `All Invoice Dates Starting from ${startDate}`;
    }

    if (endDate) {
      return `All Invoice Dates Starting Ending before ${endDate}`;
    }

    return 'N/A';
  })();

  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <View style={styles.titleSection}>
          <Text key={'title'} style={styles.title}>Buckeye Hone Days in Process - {dateRangeTitle}</Text>
        </View>
        {/* Table */}
        <View>
          {report.data.map((customer, index) => (
            <View key={`${customer.name} - ${index}`}>
              <Text style={styles.customerHeader}>{customer.name}</Text>
              <View style={styles.tableContainer}>
                <Text style={styles.columnHeader}>SO #</Text>
                <Text style={[styles.columnHeader, styles.wideColumn]}>Customer</Text>
                <Text style={styles.columnHeader}>Date Recd</Text>
                <Text style={styles.columnHeader}>Invoice Date</Text>
                <Text style={[styles.columnHeader, styles.lastColumn]}>Days In Process</Text>
              </View>
              {customer.orders.map((order, index) => (
                <View key={`${order.soNumber} - ${index}`} style={styles.tableContainer}>
                  <Text style={styles.cell}>{order.soNumber}</Text>
                  <Text style={[styles.cell, styles.wideColumn]}>{order.customerName}</Text>
                  <Text style={styles.cell}>{order.dateReceived}</Text>
                  <Text style={styles.cell}>{order.invoiceDate}</Text>
                  <Text style={[styles.cell, styles.lastColumn]}>{order.daysInProcess}</Text>
                </View>
              ))}
              <View style={styles.tableContainer}>
                <Text style={styles.totalsHeadersLeft}>Average for {customer.name}: </Text>
                <Text style={styles.totalsValue}>{customer.averageDaysInProcess}</Text>
              </View>
            </View>

          ))}
          <View style={styles.tableContainer}>
            <Text style={styles.totalsHeadersLeft}>Averge for Period: </Text>
            <Text style={styles.totalsValue}>{report.averageForPeriod}</Text>
          </View>
          <View style={styles.tableContainer}>
            {/* Intentionally left blank */}
          </View>
        </View>
        <View style={styles.tableBorder} />
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )

}

export default ClosedMasterPDF;
