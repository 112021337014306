import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    borderBottom: '1px solid #000',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: '100%',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableCol: {
    flex: 1,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: 10,
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
});

const ProductionListPDF = ({ data, startDate, endDate }) => {
  const dateRangeTitle = (() => {
    if (!startDate && !endDate) return 'All Due Dates';
    if (startDate && endDate) return `All Due Dates between ${startDate} and ${endDate}`;
    if (startDate) return `All Due Dates Starting from ${startDate}`;
    if (endDate) return `All Due Dates Ending before ${endDate}`;
    return '';
  })();

  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <Text style={styles.title}>Production List Report: {dateRangeTitle}</Text>
        {data.map((machine) => (
          <View key={machine._id} style={styles.section}>
            <Text style={styles.header}>Machine: {machine?.machineName || 'Unknown Machine and Operators'}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Order#</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Received</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Mill No</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Pieces</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>OAL</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Feet</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>ID</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>ID Upper</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>ID Lower</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>OD</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Weight</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Hours</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCellHeader}>Due</Text></View>
              </View>
              {machine.jobs.map((job) => (
                <View key={job.soNumber} style={styles.tableRow}>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{job.soNumber}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatDate(job.recvd)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{job.millNo}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.pieces)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.OAL)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.feet)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.innerDiameter, 3, true)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.innerDiameterUpper, 3, true)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.innerDiameterLower, 3, true)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.outerDiameter, 3, true)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.weight)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatNumber(job.hours)}</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>{formatDate(job.dueDate)}</Text></View>
                </View>
              ))}
            </View>
          </View>
        ))}
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default ProductionListPDF;
