import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, useTheme, IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import DaysInProcessPDF from '../../../pdf/DaysInProcessPDF';
import CloseIcon from '@mui/icons-material/Close';
import CustomerSearch from '../../../components/search/CustomerSearch';
import DateRangePicker from '../../../components/DateRangePicker';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DetailPanelContent({ row: rowProp }) {

  const columns = [
    { field: 'soNumber', headerName: 'Order Number', flex: 1 },
    { field: 'customerName', headerName: 'Customer', flex: 1 },
    { field: 'dateReceived', headerName: 'Date Received', flex: 1 },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1 },
    { field: 'daysInProcess', headerName: 'Days In Process', flex: 1 },
  ]

  return (
    <Paper>
      <DataGridPro
        density='compact'
        rows={rowProp.orders}
        columns={columns}
        sx={{ flex: 1, p: 1 }}
        hideFooter
        getRowId={(row) => row.soNumber}
      />
    </Paper>
  )
}

const DaysInProcessReport = observer(() => {
  const [report, setReport] = useState({
    results: {
      data: [],
      totals: {}
    },
    message: '',
    status: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);
  const [invoiceDateTo, setInvoiceDateTo] = useState(null);

  const theme = useTheme();

  const handleClose = () => {
    setViewPdf(false);
  }

  /**
  * Get the Days in Process report
  * @param {*} startDate - YYYY-MM-DD
  * @param {*} endDate - YYYY-MM-DD
  */
  const fetchDaysInProcessReport = async (startDate, endDate) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(startDate) && /^\d{4}-\d{2}-\d{2}$/.test(endDate)) {
      setLoading(true);

      const daysInProcessReportParams = {
        startDate,
        endDate,
        customerId
      };

      ReportsStore.fetchDaysInProcessReport(daysInProcessReportParams).then(() => {
        setReport(ReportsStore.detailedReports.daysInProcess);
        setLoading(false);
      }).catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch days in process report', severity: 'error' });
        setLoading(false);
      });
    } else {
      setSnackbar({ open: true, message: 'Please select a valid date range', severity: 'warning' })
      setLoading(false);
    }
  }

  const generateReports = () => {
    const start = dayjs(invoiceDateFrom).startOf('day').format('YYYY-MM-DD');
    const end = dayjs(invoiceDateTo).endOf('day').format('YYYY-MM-DD');
    fetchDaysInProcessReport(start, end);
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'totalDays', headerName: 'Total Value', flex: 1 },
    { field: 'count', headerName: 'Count', flex: 1 },
    { field: 'averageDaysInProcess', headerName: 'Average Days In Process', flex: 1 }
  ]

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = React.useCallback(
    () => 250,
    []
  )

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Days in Process Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <CustomerSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setCustomerId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
                showInactive={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DateRangePicker
                  label='Invoice Date Range'
                  defaultValue='This Week'
                  onChange={({ startDate, endDate }) => {
                    setInvoiceDateFrom(startDate);
                    setInvoiceDateTo(endDate);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }} disabled={loading}>Get Days in Process</Button>
              {report.results.data.length > 0 && (
                <Button
                  onClick={() => setViewPdf(true)} sx={{ width: '200px' }} disabled={loading}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width="100%" height="100%">
                <DaysInProcessPDF
                  report={report.results}
                  startDate={dayjs(invoiceDateFrom).isValid() ? dayjs(invoiceDateFrom).startOf('day').format('MM/DD/YYYY') : ''}
                  endDate={dayjs(invoiceDateTo).isValid() ? dayjs(invoiceDateTo).endOf('day').format('MM/DD/YYYY') : ''}
                />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report.results.data}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.name}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
          />
        )}

      </Box>
      {report.results.averageForPeriod && (

        <Box sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          m: 4,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 1,
          p: 1
        }}>
          <Typography variant="h5">Average for Period: {report.results.averageForPeriod}</Typography>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );

});

export default DaysInProcessReport;