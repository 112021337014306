import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, InputLabel, TextField, Box } from '@mui/material';
import dayjs from 'dayjs';

const DateRangePicker = ({ onChange, defaultValue = 'All Dates', disableAllDates = false, label = 'Select Date Range' }) => {
  const [rangeType, setRangeType] = useState(defaultValue);
  const [customDates, setCustomDates] = useState({ startDate: '', endDate: '' });

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      handleRangeChange({ target: { value: defaultValue } });
    } else if (defaultValue?.startDate && defaultValue?.endDate) {
      setRangeType('Custom Date Ranges');
      setCustomDates({
        startDate: defaultValue.startDate || '',
        endDate: defaultValue.endDate || '',
      });
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleRangeChange = (event) => {
    const selectedRange = event.target.value;
    setRangeType(selectedRange);

    let startDate = null;
    let endDate = null;

    switch (selectedRange) {
      case 'Today':
        startDate = endDate = dayjs();
        break;
      case 'This Week':
        startDate = dayjs().startOf('week');
        endDate = dayjs().endOf('week');
        break;
      case 'This Week-To-Date':
        startDate = dayjs().startOf('week');
        endDate = dayjs();
        break;
      case 'This Month':
        startDate = dayjs().startOf('month');
        endDate = dayjs().endOf('month');
        break;
      case 'This Month-To-Date':
        startDate = dayjs().startOf('month');
        endDate = dayjs();
        break;
      case 'This Quarter':
        startDate = dayjs().startOf('quarter');
        endDate = dayjs().endOf('quarter');
        break;
      case 'This Quarter-To-Date':
        startDate = dayjs().startOf('quarter');
        endDate = dayjs();
        break;
      case 'This Year':
        startDate = dayjs().startOf('year');
        endDate = dayjs().endOf('year');
        break;
      case 'This Year-To-Date':
        startDate = dayjs().startOf('year');
        endDate = dayjs();
        break;
      case 'Last Week':
        startDate = dayjs().subtract(1, 'week').startOf('week');
        endDate = dayjs().subtract(1, 'week').endOf('week');
        break;
      case 'Last Month':
        startDate = dayjs().subtract(1, 'month').startOf('month');
        endDate = dayjs().subtract(1, 'month').endOf('month');
        break;
      case 'Last Quarter':
        startDate = dayjs().subtract(1, 'quarter').startOf('quarter');
        endDate = dayjs().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'Last Year':
        startDate = dayjs().subtract(1, 'year').startOf('year');
        endDate = dayjs().subtract(1, 'year').endOf('year');
        break;
      case 'Last 30 Days':
        startDate = dayjs().subtract(30, 'days');
        endDate = dayjs();
        break;
      case 'Last 3-Months':
        startDate = dayjs().subtract(3, 'month').startOf('month');
        endDate = dayjs().subtract(1, 'month').endOf('month');
        break;
      case 'Custom Date Ranges':
        startDate = customDates.startDate;
        endDate = customDates.endDate;
        break;
      default:
        startDate = endDate = null;
    }

    if (selectedRange !== 'Custom Date Ranges') {
      onChange({ startDate, endDate });
    }
  };

  const handleCustomDateChange = (type, value) => {
    setCustomDates((prevDates) => ({
      ...prevDates,
      [type]: value,
    }));

    const parsedDate = dayjs(value, 'YYYY-MM-DD', true);
    if (parsedDate.isValid()) {
      onChange({
        ...customDates,
        [type]: parsedDate.format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="date-range-label">{label}</InputLabel>
        <Select
          labelId="date-range-label"
          value={rangeType}
          onChange={handleRangeChange}
          label={label}
        >
          {!disableAllDates && <MenuItem value="All Dates">All Dates</MenuItem>}
          <MenuItem value="Custom Date Ranges">Custom Date Ranges</MenuItem>
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="This Week">This Week</MenuItem>
          <MenuItem value="This Week-To-Date">This Week-To-Date</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
          <MenuItem value="This Month-To-Date">This Month-To-Date</MenuItem>
          <MenuItem value="This Quarter">This Quarter</MenuItem>
          <MenuItem value="This Quarter-To-Date">This Quarter-To-Date</MenuItem>
          <MenuItem value="This Year">This Year</MenuItem>
          <MenuItem value="This Year-To-Date">This Year-To-Date</MenuItem>
          <MenuItem value="Last Week">Last Week</MenuItem>
          <MenuItem value="Last Month">Last Month</MenuItem>
          <MenuItem value="Last Quarter">Last Quarter</MenuItem>
          <MenuItem value="Last Year">Last Year</MenuItem>
          <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
          <MenuItem value="Last 3-Months">Last 3 Months</MenuItem>
        </Select>
      </FormControl>

      {rangeType === 'Custom Date Ranges' && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={customDates.startDate}
            onChange={(e) => handleCustomDateChange('startDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            label="End Date"
            type="date"
            value={customDates.endDate}
            onChange={(e) => handleCustomDateChange('endDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

export default DateRangePicker;
