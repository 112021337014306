import React, { useState, useEffect } from "react";
import { Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import EmployeeStore from "../../stores/EmployeeStore";
import { debounce } from "lodash";

/**
 * Component for searching for a employee with autocomplete
 * MUST supply employees array
 * @param {number} margin - margin for the component
 * @param {function} handleChange - function to handle change of employee
 * @param {array} employees - array of employees
 * @param {string} value - value of the employee
 * @returns  
 */
const EmployeeSearch = ({ margin = 0, handleChange, label = "Search Employees", initialEmployeeId, marginTop, showInactive=false }) => {

    const [employees, setEmployees] = useState({
        results: [],
        totalCount: 0,
    });
    const [initEmployee, setInitEmployee] = useState(null);
    const [open, setOpen] = useState(false);
    const loading = open && employees.totalCount === 0;

    // Search for employees every 500ms with given search value
    const handleInputChange = debounce((event, value, reason) => {
        const numOptions = employees.results.filter((employee) =>
            employee.firstName.toLowerCase().includes(value.toLowerCase()) ||
            employee.lastName.toLowerCase().includes(value.toLowerCase())
        );
        if (value.length > 0 && numOptions.length < 10 && reason !== 'reset') {

            const filter = {
                $or: [
                    { firstName: { $regex: value, $options: 'i' } },
                    { lastName: { $regex: value, $options: 'i' } },
                    { nameID: { $regex: value, $options: 'i' } },
                    { employeeId: { $regex: value, $options: 'i' } }
                ]
            }

            //Search for employees with the search value
            getPaginatedEmployees(1, 10, 'lastName', filter, '', 'lastName');
        }
        if (reason === 'reset') {
            setInitEmployee(event)
        }
    }, 500);

    const getPaginatedEmployees = async (page, limit, sort, filter, search, searchBy) => {

        const activeFilter = {
            ...filter,
            isActive: true
        };
        const usedFilter = showInactive ? filter : activeFilter;

        try {
            await EmployeeStore.fetchPaginatedEmployees(page, limit, sort, usedFilter, search, searchBy);
            setEmployees(EmployeeStore.paginatedEmployees);
        }
        catch (error) {
            console.error('Failed to get employees:', error);
        }
    };

    useEffect(() => {

        if (initialEmployeeId) {
            EmployeeStore.fetchEmployeeById(initialEmployeeId).then((employee) => {
                setInitEmployee(employee);
            })
        }

        let active = true;

        let page = 1;
        let pageSize = 10;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await getPaginatedEmployees(
                page,
                pageSize,
                'lastName',
                {},
                '',
                'lastName'
            );
            if (active) {
                setEmployees(EmployeeStore.paginatedEmployees);
            }
        })();
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setEmployees({
                results: [],
                totalCount: 0,
            });
        }
    }, [open]);

    return (
        <Grid container margin={margin} marginTop={(marginTop || marginTop === 0) ? marginTop : (margin + 2)}>
            {employees ? (
                <>
                    <Grid item xs={12}>

                        {initEmployee ? (
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="employee"
                                getOptionLabel={(option) => `${option.lastName}, ${option.firstName} ${option.middleName} - ${option.employeeId} - ${option.nameID}`}
                                options={employees.results}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                onInputChange={handleInputChange}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                isOptionEqualToValue={(option, value) => {
                                    return option._id === value._id;
                                }}
                                value={initEmployee}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={label}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                }
                            />
                        ) : (
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="employee"
                                getOptionLabel={(option) => `${option.lastName}, ${option.firstName} ${option.middleName} - ${option.employeeId} - ${option.nameID}`}
                                options={employees.results}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                onInputChange={handleInputChange}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                isOptionEqualToValue={(option, value) => {
                                    return option._id === value._id;
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={label}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                }
                            />
                        )}

                    </Grid>
                </>
            ) : (
                <>
                    Loading Employees...
                </>
            )}
        </Grid>

    )
};

export default EmployeeSearch;