import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class EmployeeStore {
  employees = [];
  paginatedEmployees = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  }
  employee = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchEmployees() {
    try {
      const { results } = await apiHandler.get('/employees');
      this.employees = results;
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  }

  async fetchPaginatedEmployees(
    page = 1,
    pageSize = 10,
    sortBy = 'lastName',
    filter = {},
    search = '',
    searchField = 'lastName'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/employees?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedEmployees = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated employees:", error);
    }
  }

  async fetchEmployeeById(employeeId) {
    try {
      const response = await apiHandler.get(`/employees/${employeeId}`);
      this.employee = await response;
      return response;
    } catch (error) {
      console.error("Error fetching employee by ID:", error);
    }
  }

  async createEmployee(employeeData) {
    try {
      const newEmployee = await apiHandler.post('/employees', employeeData);
      this.employees.push(newEmployee);
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  }

  async updateEmployee(employeeId, employeeData) {
    try {
      const updatedEmployee = await apiHandler.put(`/employees/${employeeId}`, employeeData);
      const index = this.employees.findIndex(employee => employee._id === employeeId);
      if (index > -1) {
        this.employees[index] = updatedEmployee;
      }
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  }

  async deleteEmployee(employeeId) {
    try {
      await apiHandler.delete(`/employees/${employeeId}`);
      this.employees = this.employees.filter(employee => employee._id !== employeeId);
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  }

  // Additional methods as needed...
}

export default new EmployeeStore();
