import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { formatDate, formatNumber } from '../utils/numberFormatting';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    fontSize: 10,
  },
  tableCellRight: {
    fontSize: 10,
    textAlign: 'right',
  },
  tableCellRightHeader: {
    fontSize: 10,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  gapRow: {
    backgroundColor: '#060080',
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
});

const ProductionListPDF = ({ data }) => {
  const dailyData = data.dailyData;
  const monthlyTotals = data.monthlyTotals;

  const groupedData = Object.entries(dailyData).reduce((acc, [date, { dailyTotal, customers }]) => {
    const month = date ? dayjs(date).format('YYYY-MM') : '';
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push({ date, dailyTotal, customers });
    return acc;
  }, {});

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.header}>Buckeye Hone Footage Report</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: '#060080' }]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Due Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Customer</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Footage</Text>
            </View>
          </View>
          {Object.keys(groupedData).map((month) => (
            <React.Fragment key={month}>
              {groupedData[month].map(({ date, dailyTotal, customers }) => {
                const filteredCustomers = Object.entries(customers).filter(([customer]) => !customer.includes('NameNotFound'));
                if (filteredCustomers.length > 0) {
                  return (
                    <React.Fragment key={date}>
                      {filteredCustomers.map(([customer, footage]) => (
                        <View key={customer} style={styles.tableRow}>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{formatDate(date, 'YYYY-MM-DD', date)}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{customer}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{formatNumber(footage, 2)}</Text>
                          </View>
                        </View>
                      ))}
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}></View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCellRightHeader}>{formatDate(date, 'YYYY-MM-DD', date)} Total</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{formatNumber(dailyTotal, 2) || 0}</Text>
                        </View>
                      </View>
                      {monthlyTotals[month] ? (
                        <React.Fragment>
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}></View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCellRightHeader}>Monthly Total</Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{formatNumber(monthlyTotals[month], 2) || 0}</Text>
                            </View>
                          </View>
                          <View style={[styles.tableRow, styles.gapRow]}>
                            <View style={styles.tableCol}></View>
                            <View style={styles.tableCol}></View>
                            <View style={styles.tableCol}></View>
                          </View>
                        </React.Fragment>
                      ) : (
                        <View style={[styles.tableRow, styles.gapRow]}>
                          <View style={styles.tableCol}></View>
                          <View style={styles.tableCol}></View>
                          <View style={styles.tableCol}></View>
                        </View>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </React.Fragment>
          ))}
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default ProductionListPDF;
