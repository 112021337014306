import { Document, Page, Text, View } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '60%'
  },
  columnHeader: {
    width: '50%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '50%',
    fontSize: 10,
    textAlign: 'center',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
};

const PlugsPDF = ({ report, startDate, endDate }) => {
  // Determine the title based on the start and end dates
  const dateRangeTitle = (() => {
    if (!startDate && !endDate) {
      return 'All Due Dates';
    }

    if (startDate && endDate) {
      return `All Due Dates between ${startDate} and ${endDate}`;
    }

    if (startDate) {
      return `All Due Dates Starting from ${startDate}`;
    }

    if (endDate) {
      return `All Due Dates Starting Ending before ${endDate}`;
    }

    return 'N/A';
  })();

  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone Plugs Report - {dateRangeTitle}</Text>
        </View>
        {/* Table */}
        <View>
          <View style={styles.tableContainer}>
            <Text style={styles.columnHeader}>honeToMax</Text>
            <Text style={[styles.columnHeader, styles.lastColumn]}>plugs</Text>
          </View>
          {report.map((item, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.cell}>{item.honeToMax}</Text>
              <Text style={[styles.cell, styles.lastColumn]}>{item.plugs}</Text>
            </View>
          ))}
        </View>
        <View style={styles.tableBorder} />
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )

}

export default PlugsPDF;