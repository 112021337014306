import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import PayablesByVendorPDF from '../../../pdf/PayablesByVendorPDF';
import VendorSearch from '../../../components/search/VendorSearch';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangePicker from '../../../components/DateRangePicker';
import { formatCurrency, formatDate, formatNumber } from '../../../utils/numberFormatting';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PayablesByVendorReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [vendorId, setVendorId] = useState('');
  const [datePaidFrom, setDatePaidFrom] = useState(null);
  const [datePaidTo, setDatePaidTo] = useState(null);

  const theme = useTheme();

  const fetchPayablesByVendor = async (vendorId, datePaidFrom, datePaidTo) => {
    setLoading(true);
    ReportsStore.fetchPayablesByVendor(vendorId, datePaidFrom, datePaidTo).then(() => {
      setReport(ReportsStore.detailedReports.payablesByVendor);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch payables by vendor report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    if (!vendorId) {
      setSnackbar({ open: true, message: 'You must select an Vendor.' });
    } else {
      const enteredDatePaidFrom = datePaidFrom ? dayjs(datePaidFrom).startOf('day').format('YYYY-MM-DD') : '';
      const enteredDatePaidTo = datePaidTo ? dayjs(datePaidTo).endOf('day').format('YYYY-MM-DD') : '';

      fetchPayablesByVendor(vendorId, enteredDatePaidFrom, enteredDatePaidTo);
    }
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'payablesId', headerName: 'ID', flex: 1 },
    { field: 'invoiceAmount', headerName: 'Invoice Amount', flex: 1, valueFormatter: ({ value }) => formatCurrency(value) },
    { field: 'paidAmount', headerName: 'Paid Amount', flex: 1, valueFormatter: ({ value }) => formatCurrency(value) },
    { field: 'datePaid', headerName: 'Date Paid', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    { field: 'dueDate', headerName: 'Due Date', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
    { field: 'vendor', headerName: 'Vendor', flex: 1, valueGetter: ({ row }) => row.vendor.vendorName },
    { field: 'void', headerName: 'Void', flex: 1, type: 'boolean' },
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
            <Typography variant="h4">Payables by Vendor Report</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <VendorSearch
                label="Vendor"
                sx={{ width: '100%' }}
                value={vendorId}
                handleChange={(newValue) => setVendorId(newValue?._id || '')}
                marginTop={-2}
              />
              <DateRangePicker
                label='Date Paid Range'
                defaultValue='This Week'
                onChange={({ startDate, endDate }) => {
                  setDatePaidFrom(startDate);
                  setDatePaidTo(endDate);
                }}
              />

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: 2 }}>
              <Button variant="outlined" onClick={generateReports} sx={{ width: '200px', fontWeight: 'bold' }}>
                Get Payables
              </Button>
              {report && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }} variant="outlined">
                  View as PDF
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
          {viewPdf ? (
            <Modal
              open={viewPdf}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width={'100%'} height={'100%'}>
                  <PayablesByVendorPDF report={report} />
                </PDFViewer>
              </Paper>
            </Modal>
          ) : (
            <DataGridPro
              rows={report?.result || []}
              columns={columns}
              autoHeight
              loading={loading}
              getRowId={(row) => row.payablesId}
            />
          )}
        </Box>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Box>
  );
});

export default PayablesByVendorReport;
