// MonthlySalesPDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    padding: 3,
  },
  tableCol: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 3,
    fontSize: 10,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  pageFooter: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
});

const OrdersWrittenPDF = ({ report }) => (
  <Document>
    <Page size="Letter" orientation="landscape" style={styles.page}>
      <Text style={styles.header}>Orders Written Report</Text>
      {report && (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>Order No.</Text>
            <Text style={styles.tableColHeader}>Customer Name</Text>
            <Text style={styles.tableColHeader}>PO No.</Text>
            <Text style={styles.tableColHeader}>Date Entered</Text>
            <Text style={styles.tableColHeader}>Status</Text>
            <Text style={styles.tableColHeader}>Mill No.</Text>
            <Text style={styles.tableColHeader}>Hone ID Lower</Text>
            <Text style={styles.tableColHeader}>Hone ID Upper</Text>
            <Text style={styles.tableColHeader}>Tube OD</Text>
            <Text style={styles.tableColHeader}>Due Date</Text>
            <Text style={styles.tableColHeader}>Total</Text>
          </View>
          {report.result.map((row, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{row.salesOrderNumber}</Text>
              <Text style={styles.tableCol}>{row.customerName}</Text>
              <Text style={styles.tableCol}>{row.customerPONumber}</Text>
              <Text style={styles.tableCol}>{formatDate(row.enteredDate)}</Text>
              <Text style={styles.tableCol}>{row.status}</Text>
              <Text style={styles.tableCol}>{row.millJobNumber}</Text>
              <Text style={styles.tableCol}>{formatNumber(row.honeInnerDiameter.lowerLimit, 3, true)}</Text>
              <Text style={styles.tableCol}>{formatNumber(row.honeInnerDiameter.upperLimit, 3, true)}</Text>
              <Text style={styles.tableCol}>{formatNumber(row.tubeOuterDiameter, 3, true)}</Text>
              <Text style={styles.tableCol}>{formatDate(row.dueDate)}</Text>
              <Text style={styles.tableCol}>{formatCurrency(row.total)}</Text>
            </View>
          ))}
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>Grand Total</Text>
            <Text style={[styles.tableCol, { width: '87.5%' }]}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(report.result.reduce((sum, row) => sum + row.total, 0))}
            </Text>
          </View>
        </View>
      )}
    <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
  </Document>
);

export default OrdersWrittenPDF;
