import { Document, Page, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '60%'
  },
  columnHeader: {
    width: '25%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '25%',
    fontSize: 10,
    textAlign: 'center',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  pageFooter: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
};

const SalesReceivablesPDF = ({ report }) => {
    if (!report || !Array.isArray(report.receivables)) {
        console.error('Invalid or missing report data:', report);
        return (
            <Document>
                <Page size="Letter" style={styles.page}>
                    <Text style={styles.title}>No Data Available</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="Letter" style={styles.page}>
                <View style={styles.titleSection}>
                    <Text key={'title'} style={styles.title}>Buckeye Hone Outstanding Receivables</Text>
                </View>
                {/* Table */}
                <View>
                    <View style={styles.tableContainer}>
                        <Text style={styles.columnHeader}>Customer</Text>
                        <Text style={styles.columnHeader}>Invoice Number</Text>
                        <Text style={styles.columnHeader}>Invoice Amount</Text>
                        <Text style={styles.columnHeader}>Amount Paid</Text>
                        <Text style={styles.columnHeader}>Check Amount Totals</Text>
                        <Text style={styles.columnHeader}>Check Numbers</Text>
                        <Text style={styles.columnHeader}>Invoice Date</Text>
                    </View>
                    {report.receivables.map((rec, index) => {
                        return (
                            <View key={index} style={styles.tableContainer}>
                              <Text style={styles.cell}>{rec.customerName || ''}</Text>
                              <Text style={styles.cell}>{rec.invoiceNumber || ''}</Text>
                              <Text style={styles.cell}>
                                    {rec.invoiceAmount
                                        ? `$${parseFloat(rec.invoiceAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                        : '$0.00'}
                                </Text>
                                <Text style={styles.cell}>
                                    {rec.amountPaid
                                        ? `$${parseFloat(rec.amountPaid).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                        : '$0.00'}
                                </Text>
                                <Text style={styles.cell}>
                                    {rec.checkAmountTotals
                                        ? `$${parseFloat(rec.checkAmountTotals).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                        : '$0.00'}
                                </Text>
                                <Text style={styles.cell}>{rec.checkNumbers || ''}</Text>
                                <Text style={styles.cell}>{formatDate(rec.invoiceDate)}</Text>
                            </View>
                        );
                    })}
                    <View style={styles.tableContainer}>
                        {/* Intentionally left blank */}
                    </View>
                </View>
                <View style={styles.tableBorder} />
                <Text
                    style={styles.pageFooter}
                    render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                    fixed
                  />
              </Page>
        </Document>
    );
};

export default SalesReceivablesPDF;
