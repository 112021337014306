import dayjs from 'dayjs';

function parseNumberValue(value, defaultValue = 0) {
  if (typeof value === 'object' && value !== null) {
    // Check for $numberDecimal or similar nested properties
    if (value.$numberDecimal) {
      return parseFloat(value.$numberDecimal);
    }
  }
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
}

function formatCurrency(value, precision = 2, defaultValue = 0) {
  const validValue = parseNumberValue(value, defaultValue);
  return validValue.toLocaleString('en-US', { 
    style: 'currency', 
    currency: 'USD', 
    minimumFractionDigits: precision, 
    maximumFractionDigits: precision 
  });
}

function formatDate(date, dateFormat = 'YYYY-MM-DD', defaultValue = '') {
  return (date && dayjs(date).isValid() ? dayjs(date).format(dateFormat) : defaultValue);
}

function formatNumber(value, precision = 2, fixedDecimal = false, defaultValue = '0') {
  const validValue = parseNumberValue(value, defaultValue);
  return validValue.toLocaleString('en-US', { 
    minimumFractionDigits: fixedDecimal ? precision : 0, 
    maximumFractionDigits: precision 
  });
}

export {
  formatCurrency,
  formatDate,
  formatNumber
};
