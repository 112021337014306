import { Document, Page, Text, View } from '@react-pdf/renderer';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 20,
    width: '60%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    width: '60%'
  },
  columnHeader: {
    width: '25%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cell: {
    width: '25%',
    fontSize: 10,
    textAlign: 'center',
  },
  lastColumn: {
    borderRightWidth: 0,
  },
  tableBorder: {
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  pageFooter: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
};

const SalesByCustomerPDF = ({ report }) => {
  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <View style={styles.titleSection}>
          <Text key={'title'} style={styles.title}>Buckeye Hone Sales by Customer</Text>
        </View>
        {/* Table */}
        <View>
          <View style={styles.tableContainer}>
            <Text style={styles.columnHeader}>Customer</Text>
            <Text style={styles.columnHeader}>Order No</Text>
            <Text style={styles.columnHeader}>Mill No</Text>
            <Text style={styles.columnHeader}>Due Date</Text>
            <Text style={styles.columnHeader}>Total</Text>
            <Text style={styles.columnHeader}>Status</Text>
          </View>
          {report.sales.map((order, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.cell}>{order.customerName}</Text>
              <Text style={styles.cell}>{order.orderNumber}</Text>
              <Text style={styles.cell}>{order.millJobNumber}</Text>
              <Text style={styles.cell}>{formatDate(order.dueDate)}</Text>
              <Text style={styles.cell}>{formatCurrency(order.total)}</Text>
              <Text style={[styles.cell, styles.lastColumn]}>{order.status}</Text>
            </View>

          ))}
          <View style={styles.tableContainer}>
            {/* Intentionally left blank */}
          </View>
        </View>
        <View style={styles.tableBorder} />
        <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}

export default SalesByCustomerPDF;