import { Document, Page, Text, View } from '@react-pdf/renderer';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';

const styles = {
  page: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleSection: {
    marginBottom: 20,
    width: '100%',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  groupContainer: {
    marginBottom: 10,
    width: '100%',
  },
  customerHeader: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 8,
    marginTop: 16,
  },
  tableHeader: {
    flexDirection: 'row',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 2,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  tableCellHeader: {
    flex: 1,
    fontSize: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 2,
  },
  tableCell: {
    flex: 1,
    fontSize: 7,
    textAlign: 'center',
    padding: 2,
  },
  footerRow: {
    flexDirection: 'row',
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: '#000',
    marginTop: 5,
    paddingTop: 5,
  },
  footerLabel: {
    flex: 2,
    fontSize: 8,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  footerValue: {
    flex: 1,
    fontSize: 8,
    textAlign: 'left',
  },
  pageFooter: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
};

const OpenMastersPDF = ({ report, startDate, endDate }) => {
  const dateRangeTitle = (() => {
    if (!startDate && !endDate) return 'All Due Dates';
    if (startDate && endDate) return `All Due Dates between ${startDate} and ${endDate}`;
    if (startDate) return `All Due Dates Starting from ${startDate}`;
    if (endDate) return `All Due Dates Ending before ${endDate}`;
    return 'N/A';
  })();

  const renderTableHeader = () => (
    <View style={styles.tableHeader}>
      <Text style={styles.tableCellHeader}>SO #</Text>
      <Text style={styles.tableCellHeader}>Customer</Text>
      <Text style={styles.tableCellHeader}>Mill No</Text>
      <Text style={styles.tableCellHeader}>PCS</Text>
      <Text style={styles.tableCellHeader}>Tube OD</Text>
      <Text style={styles.tableCellHeader}>Hone to Max</Text>
      <Text style={styles.tableCellHeader}>Feet</Text>
      <Text style={styles.tableCellHeader}>Due Date</Text>
      <Text style={styles.tableCellHeader}>Date Recd</Text>
      <Text style={styles.tableCellHeader}>Cost / Piece</Text>
      <Text style={styles.tableCellHeader}>Cost / Foot</Text>
      <Text style={styles.tableCellHeader}>Value</Text>
      <Text style={styles.tableCellHeader}>Date Entered</Text>
      <Text style={styles.tableCellHeader}>Ship Date</Text>
    </View>
  );

  const renderCustomerSection = (customer) => (
    <View style={styles.groupContainer}>
      {/* Render customer header */}
      <Text style={styles.customerHeader}>{customer.name}</Text>

      {/* Render table header */}
      {renderTableHeader()}

      {/* Render orders */}
      {customer.orders.map((order, idx) => (
        <View key={`${order.soNumber}-${idx}`} style={styles.tableRow}>
          <Text style={styles.tableCell}>{order.soNumber}</Text>
          <Text style={styles.tableCell}>{order.customer}</Text>
          <Text style={styles.tableCell}>{order.millNo}</Text>
          <Text style={styles.tableCell}>{formatNumber(order.PCS)}</Text>
          <Text style={styles.tableCell}>{formatNumber(order.tubeOD, 3, true)}</Text>
          <Text style={styles.tableCell}>{formatNumber(order.honeToMax, 3, true)}</Text>
          <Text style={styles.tableCell}>{formatNumber(order.feet)}</Text>
          <Text style={styles.tableCell}>{formatDate(order.dueDate)}</Text>
          <Text style={styles.tableCell}>{formatDate(order.dateRecd)}</Text>
          <Text style={styles.tableCell}>{formatCurrency(order.costPerPiece)}</Text>
          <Text style={styles.tableCell}>{formatCurrency(order.costPerFoot)}</Text>
          <Text style={styles.tableCell}>{formatCurrency(order.value)}</Text>
          <Text style={styles.tableCell}>{formatDate(order.dateEntered)}</Text>
          <Text style={styles.tableCell}>{formatDate(order.shipDate)}</Text>
        </View>
      ))}

      {/* Render customer total */}
      <View style={styles.footerRow}>
        <Text style={styles.footerLabel}>{customer.name} Total:</Text>
        <Text style={styles.footerValue}>{formatCurrency(customer.totalValue)}</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="Letter" orientation="landscape" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={styles.title}>Buckeye Hone Open Masters List - {dateRangeTitle}</Text>
        </View>

        {/* Render all customers */}
        {report.data.map((customer, index) => renderCustomerSection(customer))}

        {/* Render grand total */}
        <View style={styles.footerRow}>
          <Text style={styles.footerLabel}>Grand Total:</Text>
          <Text style={styles.footerValue}>{formatCurrency(report.grandTotal)}</Text>
        </View>

        {/* Render page footer */}
        <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default OpenMastersPDF;
