import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PrintIcon from '@mui/icons-material/Print';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import ProductionFootagePDF from '../../../pdf/ProductionFootagePDF';
import CloseIcon from '@mui/icons-material/Close';
import CustomerSearch from '../../../components/search/CustomerSearch';
import MachineSearch from '../../../components/search/MachineSearch';
import Autocomplete from '@mui/material/Autocomplete';
import DateRangePicker from '../../../components/DateRangePicker';
import { formatDate, formatNumber } from '../../../utils/numberFormatting';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Detail Panel Content for expanded view
function DetailPanelContent({ row }) {
  const columns = [
    { field: 'customer', headerName: 'Customer', flex: 1 },
    { 
      field: 'dailyTotal', 
      headerName: 'Daily Total', 
      valueFormatter: ({ value }) => formatNumber(value, 2, true), 
      flex: 1 
    }
  ];

  const rows = Object.keys(row.customers || {}).map((customer) => ({
    id: customer,
    customer,
    dailyTotal: row.customers[customer]
  }));

  return (
    <Paper>
      <DataGridPro
        density="compact"
        rows={rows}
        columns={columns}
        sx={{ flex: 1, p: 1 }}
        hideFooter
        getRowId={(row) => row.id}
      />
    </Paper>
  );
}

const ProductionFootageReport = observer(() => {
  const [report, setReport] = useState({
    result: {
      dailyData: {},
      monthlyTotals: {}
    },
    summary: {
      totalFootage: 0,
      ordersWithMissingData: 0,
      uniqueCustomerCount: 0,
      averageFootagePerDay: "0"
    },
    message: '',
    status: ''
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [machineId, setMachineId] = useState('');
  const [serviceOrderNumber, setServiceOrderNumber] = useState('');
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [dueDateFrom, setDueDateFrom] = useState(null);
  const [dueDateTo, setDueDateTo] = useState(null);
  const [millNo, setMillNo] = useState('');
  const [promiseDateFrom, setPromiseDateFrom] = useState(null);
  const [promiseDateTo, setPromiseDateTo] = useState(null);
  const [enteredDateFrom, setEnteredDateFrom] = useState(null);
  const [enteredDateTo, setEnteredDateTo] = useState(null);
  const [totalBy, setTotalBy] = useState(null);

  const theme = useTheme();

  /**
   * Get the production footage report
   * @param {*} startDate - YYYY-MM-DD
   * @param {*} endDate - YYYY-MM-DD
   */
  const fetchProductionFootageReport = async (params) => {
    setLoading(true);
    ReportsStore.fetchProductionFootageReport(params).then(() => {
      setReport(ReportsStore.detailedReports.productionFootage);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch production footage report', severity: 'error' });
      setLoading(false);
    });
  };

  const generateFootageReport = () => {
    const dueStart = dayjs(dueDateFrom).isValid() ? dayjs(dueDateFrom).startOf('day').format('YYYY-MM-DD') : '';
    const dueEnd = dayjs(dueDateTo).isValid() ? dayjs(dueDateTo).endOf('day').format('YYYY-MM-DD') : '';
    const promiseStart = dayjs(promiseDateFrom).isValid() ? dayjs(promiseDateFrom).startOf('day').format('YYYY-MM-DD') : '';
    const promiseEnd = dayjs(promiseDateTo).isValid() ? dayjs(promiseDateTo).endOf('day').format('YYYY-MM-DD') : '';
    const enteredStart = dayjs(enteredDateFrom).isValid() ? dayjs(enteredDateFrom).startOf('day').format('YYYY-MM-DD') : '';
    const enteredEnd = dayjs(enteredDateTo).isValid() ? dayjs(enteredDateTo).endOf('day').format('YYYY-MM-DD') : '';

    const productionFootageParams = {
      customerId,
      machineId,
      serviceOrderNumber,
      purchaseOrderNumber,
      dueDateFrom: dueStart,
      dueDateTo: dueEnd,
      millNo,
      promiseDateFrom: promiseStart,
      promiseDateTo: promiseEnd,
      enteredDateFrom: enteredStart,
      enteredDateTo: enteredEnd,
      totalBy
    }

    fetchProductionFootageReport(productionFootageParams);
  }

  const printRef = useRef();

  const handleClose = () => {
    setViewPdf(false);
  };

  const totalByOptions = ['Pick Day', 'Month']

  const rows = Object.keys(report.result.dailyData).map((date) => ({
    id: date || 'No Date',
    date: date || 'No Date',
    dailyTotal: report.result.dailyData[date]?.dailyTotal || 0,
    customers: report.result.dailyData[date]?.customers || {}
  }));

  const columns = [
    { field: 'date', headerName: 'Date', valueFormatter: ({ value }) => formatDate(value, 'YYYY-MM-DD', value), flex: 1 },
    { field: 'dailyTotal', headerName: 'Daily Total', valueFormatter: ({ value }) => formatNumber(value, 2), flex: 1 }
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 250, []);

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Production Footage Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            {/* Customer Name Dropdown */}
            <Grid item xs={12} md={6}>
              <CustomerSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setCustomerId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
                showInactive={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MachineSearch handleChange={(event) => setMachineId(event ? event._id : '')} marginTop={0} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-1"
                  label="Service Order #"
                  multiline
                  onChange={(event) => setServiceOrderNumber(event.target.value)}
                  maxrows={4}
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-2"
                  label="Purchase Order #"
                  multiline
                  onChange={(event) => setPurchaseOrderNumber(event.target.value)}
                  maxrows={4}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Due Date From"
                  value={dueDateFrom}
                  onChange={(newValue) => setDueDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Due Date To"
                  value={dueDateTo}
                  onChange={(newValue) => setDueDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible-1"
                  label="Mill/Job #"
                  multiline
                  onChange={(event) => setMillNo(event.target.value)}
                  maxrows={4}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Promise Date From"
                  value={promiseDateFrom}
                  onChange={(newValue) => setPromiseDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Promise Date To"
                  value={promiseDateTo}
                  onChange={(newValue) => setPromiseDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
              <DateRangePicker
                  label='Entered Date Range'
                  defaultValue='This Week'
                onChange={({ startDate, endDate }) => {
                    setEnteredDateFrom(startDate);
                    setEnteredDateTo(endDate);
                }}
              />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Autocomplete
                  fullWidth
                  id="totalBy"
                  label="Total By"
                  multiline='true'
                  options={totalByOptions}
                  onChange={(event) => setTotalBy(event.target.value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Total By" variant="outlined" />
                  )}
                  maxrows={4}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={generateFootageReport} sx={{ width: '200px', fontWeight: 'bold' }} disabled={loading}>
                Get Footage
              </Button>
              {rows.length > 0 && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }} disabled={loading}>
                  View as PDF
                </Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal open={viewPdf} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <ProductionFootagePDF
                  data={report.result}
                  startDate={dayjs(dueDateFrom).isValid() ? dayjs(dueDateFrom).format('MM/DD/YYYY') : ''}
                  endDate={dayjs(dueDateTo).isValid() ? dayjs(dueDateTo).format('MM/DD/YYYY') : ''}
                />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={rows}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.id}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', m: 4 }}>
        <Typography variant="h5">
          Total Footage: {formatNumber(report.summary?.totalFootage, 2) || 0}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', m: 4 }}>
        <Typography variant="h5">
          Average Footage / Day: {formatNumber(report.summary?.averageFootagePerDay, 2) || 0.00}
        </Typography>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default ProductionFootageReport;
