import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '11.11%', // Ensures even distribution across 9 columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  headerCell: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableCell: {
    fontSize: 10,
    textAlign: 'center',
  },
  pageFooter: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
});

const OpenInvoicePDF = ({ report }) => {
  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>Open Invoice Report</Text>
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Payables ID</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Invoice Amount</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Paid Amount</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Date Paid</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Due Date</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Invoice Date</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Invoice No</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Vendor</Text></View>
              <View style={styles.tableCol}><Text style={styles.headerCell}>Void</Text></View>
            </View>

            {/* Table Rows */}
            {report.result.map((entry, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{entry.payablesId || ''}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{formatCurrency(entry.invoiceAmount)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{formatCurrency(entry.paidAmount)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{formatDate(entry.datePaid)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{formatDate(entry.dueDate)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{formatDate(entry.invoiceDate)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{entry.invoiceNo || ''}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{entry.vendor?.vendorName || ''}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{entry.void ? 'VOID' : ''}</Text></View>
              </View>
            ))}
          </View>
        </View>
        <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default OpenInvoicePDF;
