import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  CircularProgress
} from "@mui/material";
import PayablesStore from '../../stores/PayablesStore';

const PayableForm = ({ initialPayableData, onSubmit }) => {
  const [payable, setPayable] = useState({
    invoiceNo: "",
    invoiceAmount: 0,
    commentsExternal: "",
    commentsInternal: "",
    dueDate: "",
    expenseCode: {
      _id: '',
      acctCodeId: '',
      accountCode: ''
    },
    vendor: {
      _id: '',
      vendorId: '',
      vendorName: ''
    }
    // Add other fields as needed
  });
  const [accountCodes, setAccountCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialPayableData) {
      setPayable(initialPayableData);
    }

    const fetchAccountCodes = async () => {
      setLoading(true);
      try {
        const codes = await PayablesStore.fetchAccountCodes();
        setAccountCodes(codes);
      } catch (error) {
        console.error("Failed to fetch account codes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountCodes();
  }, [initialPayableData]);

  const handleInputChange = (event) => {
    setPayable({ ...payable, [event.target.name]: event.target.value });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label="Invoice Number"
          name="invoiceNo"
          fullWidth
          value={payable.invoiceNo}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          label="Invoice Amount"
          name="invoiceAmount"
          fullWidth
          value={payable.invoiceAmount}
          onChange={(e) => setPayable({ ...payable, invoiceAmount: e.target.value })}
          onBlur={() => {
            // Format the value to two decimals on blur
            setPayable((prev) => ({
              ...prev,
              invoiceAmount: (parseFloat(prev.invoiceAmount) || 0).toFixed(2),
            }));
          }}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            )
          }}
        />
        <TextField
          label="Vendor Name"
          name="vendorName"
          fullWidth
          value={payable.vendor.vendorName}
          onChange={handleInputChange}
          margin="normal"
          disabled
        />
        <TextField
          label="Due Date"
          name="dueDate"
          type="date"
          fullWidth
          value={payable.dueDate}
          onChange={handleInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <TextField
            label="Expense Code"
            name="expenseCode"
            fullWidth
            select
            value={payable.expenseCode.accountCode}
            onChange={handleInputChange}
            margin="normal"
          >
            {accountCodes.map((code) => (
              <MenuItem key={code.id} value={code.id}>
                {code.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          label="Internal Comments"
          name="internalComments"
          fullWidth
          value={payable.internalComments}
          onChange={handleInputChange}
          margin="normal"
          multiline
          minRows={2}
          helperText="Prints on one of the A/P Reports (Open)"
        />
        <TextField
          label="External Comments"
          name="externalComments"
          fullWidth
          value={payable.externalComments}
          onChange={handleInputChange}
          margin="normal"
          multiline
          minRows={2}
          helperText="Prints on the check to the vendor"
        />
      </Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(payable)}
        >
          {initialPayableData ? "Update" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default PayableForm;
