import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, useTheme, IconButton, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import DeliveriesPDF from '../../../pdf/DeliveriesPDF';
import CloseIcon from '@mui/icons-material/Close';
import DateRangePicker from '../../../components/DateRangePicker';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeliveriesReport = observer(() => {
  const [report, setReport] = useState({
    result: {
      data: [],
      totals: {}
    },
    message: '',
    status: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [filterPromiseDate, setFilterPromiseDate] = useState(false); // New state
  const theme = useTheme();
  const [reportLayout, setReportLayout] = useState('detail');
  const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);
  const [invoiceDateTo, setInvoiceDateTo] = useState(null);

  /**
   * Get the deliveries report
   * @param {*} startDate - YYYY-MM-DD
   * @param {*} endDate - YYYY-MM-DD
   */
  const fetchDeliveriesReport = async (startDate, endDate) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(startDate) && /^\d{4}-\d{2}-\d{2}$/.test(endDate)) {
      setLoading(true);

      const deliveriesReportParams = {
        startDate,
        endDate,
        layout: reportLayout,
        filterPromiseDate,
      };

      ReportsStore.fetchDeliveriesReport(deliveriesReportParams).then(() => {
        setReport(ReportsStore.detailedReports.deliveries);
        setLoading(false);
      }).catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch deliveries report', severity: 'error' });
        setLoading(false);
      });
    } else {
      setSnackbar({ open: true, message: 'Please select a valid date range', severity: 'warning' });
      setLoading(false);
    }
  };

  const generateReports = () => {
    const start = dayjs(invoiceDateFrom).startOf('day').format('YYYY-MM-DD');
    const end = dayjs(invoiceDateTo).endOf('day').format('YYYY-MM-DD');
    fetchDeliveriesReport(start, end);
  };

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
    { field: 'customer', headerName: 'Customer', flex: 1 },
    { field: 'promiseDate', headerName: 'Promise Date', flex: 1 },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1 },
    { field: 'days', headerName: 'Days', flex: 1 },
    { field: 'onTime', headerName: 'On Time', flex: 1 },
  ]

  const totalsColumn = [
    { field: 'field', headerName: 'Totals', flex: 1 },
    { field: 'values', headerName: 'Values', flex: 1 }
  ]

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Deliveries Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DateRangePicker
                  label="Invoice Date Range"
                  defaultValue="This Week"
                  onChange={({ startDate, endDate }) => {
                    setInvoiceDateFrom(startDate);
                    setInvoiceDateTo(endDate);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 2, // Spacing between dropdown and checkbox
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="simple-select">Report Layout</InputLabel>
                  <Select
                    labelId="simple-select"
                    id="select"
                    value={reportLayout}
                    label="Report Layout"
                    onChange={(event) => setReportLayout(event.target.value)}
                  >
                    <MenuItem value="detail">Detail</MenuItem>
                    <MenuItem value="summary">Summary</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterPromiseDate}
                      onChange={(e) => setFilterPromiseDate(e.target.checked)}
                    />
                  }
                  label="Only show deliveries with a Promise Date"
                  sx={{ marginLeft: 2 }} // Optional margin for alignment
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button
                variant="outlined"
                onClick={() => generateReports()}
                sx={{ width: '200px', fontWeight: 'bold' }}
                disabled={loading}
              >
                Get Deliveries
              </Button>
              {report.result.data.length > 0 && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }} disabled={loading}>
                  View as PDF
                </Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        <DataGridPro
          rows={Object.entries(report.result.totals).map(
            ([key, value]) => ({ field: key, values: value } || {})
          )}
          columns={totalsColumn}
          autoHeight
          loading={loading}
          getRowId={(row) => row.field}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  padding: 1,
                  margin: 1,
                  color: theme.palette.background.default,
                }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <DeliveriesPDF
                  report={report.result}
                  startDate={dayjs(invoiceDateFrom).isValid() ? dayjs(invoiceDateFrom).format('MM/DD/YYYY') : ''}
                  endDate={dayjs(invoiceDateTo).isValid() ? dayjs(invoiceDateTo).format('MM/DD/YYYY') : ''}
                />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report.result.data}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.orderNumber + Math.random()}
          />
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );  
});

export default DeliveriesReport;