import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { formatNumber, formatDate } from '../utils/numberFormatting';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
  },
  subHeader: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  h4: {
    fontSize: 12,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 10,
  },
  tableColDate: {
    width: '20%',
  },
  tableColHours: {
    width: '10%',
  },
  tableColComments: {
    width: '70%',
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
  },
});

const EmployeeAttendancePDF = ({ report }) => (
  <Document>
    <Page size="Letter" style={styles.page}>
      <Text style={styles.header}>Employee Attendance Report</Text>
      {report.map((employee, index) => (
        <View key={index} style={styles.section}>
          <Text style={styles.subHeader}>Employee Name: {employee.name}</Text>
          <Text style={styles.h4}>Total: {employee.periodTotal}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCol, styles.tableColDate]}>Date</Text>
              <Text style={[styles.tableCol, styles.tableColHours]}>Hours</Text>
              <Text style={[styles.tableCol, styles.tableColComments]}>Comments</Text>
            </View>
            {employee.attendance.map((monthData, monthIndex) => (
              <React.Fragment key={monthIndex}>
                {monthData.days.map((day, dayIndex) => (
                  <View key={dayIndex} style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableColDate]}>{formatDate(day.date)}</Text>
                    <Text style={[styles.tableCol, styles.tableColHours]}>{formatNumber(day.hours, 2)}</Text>
                    <Text style={[styles.tableCol, styles.tableColComments]}>{day.comments}</Text>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCol, styles.tableColDate]}>Monthly Total</Text>
                  <Text style={[styles.tableCol, styles.tableColHours]}>
                    {formatNumber(monthData.monthlyTotal, 2)}
                  </Text>
                  <Text style={[styles.tableCol, styles.tableColComments]} />
                </View>
              </React.Fragment>
            ))}
          </View>
        </View>
      ))}
      <Text
        style={styles.footer}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default EmployeeAttendancePDF;
