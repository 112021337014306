import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  ButtonGroup,
  IconButton,
  FormControl,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NumericFormat } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const InvoiceForm = ({ invoice, onSubmit, onDelete, onChange }) => {

  const [totalCostAfterCharges, setTotalCostAfterCharges] = useState(invoice.totalAmount);

  useEffect(() => {
    updateTotalAmount();
  }, [invoice.extraCharges, invoice.totalAmount]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === 'additionalCharges' && typeof value === 'string') {
      value = parseFloat(value.replace(/[$,%]/g, '')) || 0;
    }
    onChange({
      ...invoice,
      [name]: value,
    });
  };

  const handleTextBoxchange = (text) => {
    onChange({
      ...invoice,
      additionalComments: text
    });
  }

  const handleDateChange = (name, date) => {
    const validDate = date instanceof Date && !isNaN(date) ? date : null;
    onChange({
      ...invoice,
      [name]: validDate,
    });
  };

  const handleAddCharge = () => {
    const newCharge = {
      type: "Flat",
      description: "Service Charge",
      amount: 0,
    };
    const updatedCharges = [...invoice.extraCharges, newCharge];
    onChange({
      ...invoice,
      extraCharges: updatedCharges,
    });
  };

  const handleChargeChange = (index, parameter, value) => {
    const updatedCharges = [...invoice.extraCharges];
    if (parameter === 'amount') {
      const numericValue = parseFloat(value.replace(/[$,%]/g, '')) || 0;
      updatedCharges[index][parameter] = numericValue;
    } else {
      updatedCharges[index][parameter] = value;
    }
    onChange({
      ...invoice,
      extraCharges: updatedCharges,
    });
  };

  const handleRemoveCharge = (index) => {
    const updatedCharges = invoice.extraCharges.filter((_, i) => i !== index);
    onChange({
      ...invoice,
      extraCharges: updatedCharges,
    });
  };

  const updateTotalAmount = () => {
    let totalAmount = invoice.totalAmount;

    if (typeof totalAmount === 'string') {
      totalAmount = parseFloat(totalAmount.replace(/[$,%]/g, '')) || 0;
    }

    invoice.extraCharges.forEach((charge) => {
      if (charge.type === "Flat") {
        totalAmount += charge.amount;
      } else if (charge.type === "Percentage") {
        totalAmount += (totalAmount * (charge.amount / 100));
      }
    });

    setTotalCostAfterCharges(totalAmount.toFixed(2));
  };

  return (
    <Box sx={{ pt: 2, }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <TextField
              fullWidth
              label="SO#"
              value={invoice.salesOrderNumber}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Customer Name"
              disabled
              value={invoice.customerName || invoice.customer?.companyName}
              margin="normal"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <TextField
              fullWidth
              label="Invoice Number"
              name="invoiceNumber"
              value={invoice.invoiceNumber}
              onChange={handleChange}
              margin="normal"
            />
            <DatePicker
              label="Invoice Date"
              name="invoiceDate"
              sx={{ mt: 2, mb: 1, width: '100%' }}
              value={invoice.invoiceDate ? new Date(invoice.invoiceDate) : null}
              onChange={(date) => handleDateChange("invoiceDate", date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <TextField
              fullWidth
              type="number"
              label="Bundles"
              name="bundles"
              value={invoice.bundles}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              type="number"
              label="Pieces"
              name="pieces"
              value={invoice.pieces}
              onChange={handleChange}
              margin="normal"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <TextField
              fullWidth
              type="number"
              label="Total Feet"
              name="feet"
              value={invoice.feet}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              type="number"
              label="Weight"
              name="weight"
              value={invoice.weight}
              onChange={handleChange}
              margin="normal"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <NumericFormat
              fullWidth
              label="Order Charges"
              name="additionalCharges"
              value={invoice.additionalCharges}
              onChange={handleChange}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              valueIsNumericString
              prefix='$'
              customInput={TextField}
              margin="normal"
            />
            <NumericFormat
              disabled
              label="Order Total"
              name="totalAmount"
              value={invoice.totalAmount}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              valueIsNumericString
              prefix='$'
              customInput={TextField}
              fullWidth
              margin="normal"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, width: "100%" }}>
            <TextField
              fullWidth
              type="text"
              label="Charge Description"
              name="additionalChargeDescription"
              value={invoice.additionalChargeDescription}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              type="text"
              label="Invoice Code"
              name="invoiceCode"
              value={invoice.invoiceCode}
              onChange={handleChange}
              margin="normal"
            />
          </Box>

          <Typography variant='h6'>Invoice Comments</Typography>
          {/* <ReactQuill
            value={invoice.additionalComments}
            onChange={handleTextBoxchange}
            name="additionalComments"
            style={{ marginTop: 4, marginBottom: 4 }}
            theme="snow"
          /> */}
          <TextField
            fullWidth
            type="text"
            label="Additional Comments"
            name="additionalComments"
            value={invoice.additionalComments}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Extra Charges
          </Typography>
          {invoice.extraCharges && invoice.extraCharges.map((charge, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <NumericFormat
                label={charge.type === 'Percentage' ? 'Percentage (%)' : 'Amount'}
                name="amount"
                value={charge.amount}
                onChange={(e) => handleChargeChange(index, 'amount', e.target.value)}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                valueIsNumericString
                prefix={charge.type === 'Flat' ? '$' : ''}
                suffix={charge.type === 'Percentage' ? '%' : ''}
                customInput={TextField}
                fullWidth
                margin="normal"
                sx={{ flex: '1 1 150px', minWidth: '150px', mr: 1 }}
              />

              <FormControl margin="normal" sx={{ flex: '1 1 120px', height: 56, minWidth: '120px' }}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="type selection"
                  fullWidth
                  sx={{ height: '100%', mr: 1 }}
                >
                  <Button
                    sx={{ height: '100%' }}
                    variant={charge.type === 'Flat' ? 'contained' : 'outlined'}
                    onClick={() => handleChargeChange(index, 'type', 'Flat')}
                  >
                    $
                  </Button>
                  <Button
                    sx={{ height: '100%' }}
                    variant={charge.type === 'Percentage' ? 'contained' : 'outlined'}
                    onClick={() => handleChargeChange(index, 'type', 'Percentage')}
                  >
                    %
                  </Button>
                </ButtonGroup>
              </FormControl>

              <TextField
                label="Description"
                name="description"
                fullWidth
                value={charge.description}
                onChange={(e) => handleChargeChange(index, 'description', e.target.value)}
                margin="normal"
                sx={{ flex: '2 1 250px', minWidth: '250px', ml: 1 }}
              />

              <IconButton onClick={() => handleRemoveCharge(index)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          ))}
          <Button variant="contained" onClick={handleAddCharge} sx={{ alignSelf: 'flex-start', mt: 2 }}>
            Add Extra Charge
          </Button>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3, width: "100%" }}>
          <TextField
            disabled
            fullWidth
            type="number"
            label="Total Cost After Charges"
            value={totalCostAfterCharges}
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            pt: 4,
            gap: 2,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            Add Invoice
          </Button>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default InvoiceForm;
